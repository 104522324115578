<template>
  <div class="animated fadeIn" style="min-height: 1500px; background: black;">
    <b-card no-body bg-variant="dark" style="padding: 10px;">
      <b-card no-body bg-variant="dark" style="min-height: 300px;">
        <b-row style="height: 10px;">
          <b-col sm="12"> </b-col>
        </b-row>
        <b-row style="margin: 0px;">
          <b-col sm="3">
            <b-row>
              <b-col sm="12" md="4" style="vertical-align: center; text-align: center;">
                <img src="../../static/img/gif/wisdomera2020awards.gif" style="height: 100px;">
              </b-col>
              <b-col sm="12" md="8" style="vertical-align: center; text-align: center;">
                <span>{{ $t('wdm16.11017') }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <p>{{ $t('wdm16.11018') }}</p>
                <ul>
                  <li>{{ $t('wdm16.11019') }}</li>
                  <li>{{ $t('wdm16.11020') }}</li>
                  <li>{{ $t('wdm16.11021') }}</li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <video controls width="100%" height="250px">
              <source src="../../static/vid/wisdomera_2020_awards.mp4" type="video/mp4">
            </video>
          </b-col>
          <b-col sm="3">
            <template v-if="d_waiData.wai.length > 0">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="2" style="text-align: right; padding: 3px;">
                  <img src="../../static/img/slider/image8.png" style="border-radius: 10px; width: 50px;">
                </b-col>
                <b-col sm="12" md="8" style="text-align: center;padding: 3px;">
                  {{ $t('wdm16.11022') }}
                </b-col>
                <b-col sm="12" md="2" style="text-align: left;padding: 3px;">
                  <img src="../../static/img/slider/image8.png" style="border-radius: 10px; width: 50px;" class="rotateimg180">
                </b-col>
              </b-row>
              <div id="WaiListScrollbar" style="overflow-y: auto; height: 200px; overflow-x: hidden;">
                <template v-for="(waim, waim_ind) in d_waiData.wai">
                  <b-row style="margin-bottom: 5px;">
                    <b-col sm="12">
                      <template v-if="waim.type === 'wdm3'">
                        <a href="javascript:;" @click="f_goToWaimWdm3Project(waim.id)">
                          <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ waim.name }}
                          <small style="color: green;"> ( {{ DateFormatWithHourMin(waim.published_at)  }} ) </small>
                          <!-- <b-badge variant="warning">{{ waim.owner }}</b-badge> -->
                        </a>
                      </template>
                      <template v-if="waim.type === 'wdm24'">
                        <a href="javascript:;" @click="f_goToWaimWdm24(waim.id)">
                          <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ waim.name }}
                          <small style="color: green;"> ( {{ DateFormatWithHourMin(waim.published_at)  }} ) </small>
                          <!-- <b-badge variant="warning">{{ waim.owner }}</b-badge> -->
                        </a>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row :style="device.isMobile ? 'margin-bottom: 20px;' : 'margin-bottom: 20px;'">
                    <b-col sm="12">
                      <template v-if="waim.type === 'wdm3'">
                        <template v-for="(col, col_ind) in waim.column_list">
                          {{ col.label }}
                          <template v-if="col_ind !== waim.column_list.length - 1">
                            ,
                          </template>
                        </template>
                        parametreleri kullanılarak hazırlanmış bir model.
                      </template>
                      <template v-if="waim.type === 'wdm24'">
                        {{ waim.rule_count }} adet algoritma bulunmaktadır.
                      </template>
                      <template v-if="waim.information">
                        {{ waim.information }}
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2" style="text-align: right;">
            <img src="../../static/img/slider/image15.png" style="border-radius: 10px; width: 50px;">
          </b-col>
          <b-col sm="8">
            <b-form-group label="" label-for="search_patient" style="margin-top: 10px;">
              <b-input-group>
                <b-form-input id="wisdomera_ai" :placeholder="$t('wdm16.10983')" type="text" style="color: white; background-color: rgba(43, 81, 73, 0.5);" v-model="d_searchWisdomEraAiRegion" @keydown.enter.native="ClickEnter"></b-form-input>
                <b-input-group-append>
                  <b-button variant="secondary" @click="f_searchWaim()"><i class="fa fa-search"></i> </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="2"> </b-col>
        </b-row>
      </b-card>
      <b-row :style="'height:' + (d_drawSettings.canvas.height + 50) + 'px; background-color: #2f353a; margin: 0px;'" class="animated fadeIn">
        <b-col sm="12">
          <template v-if="d_algorithmData.show">
            <draw-algorithm :p_algorithmData="d_algorithmData" :p_drawSettings="d_drawSettings"></draw-algorithm>
          </template>
        </b-col>
      </b-row>
      <!-- <b-card no-body bg-variant="dark">
        <b-carousel id="carousel1" style="text-shadow: 1px 1px 2px #333; height: 100%;" controls indicators background="#ababab" :interval="10000" v-model="slide" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
          <b-carousel-slide style="color: black;">
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../static/img/slider/ai_1.png" alt="image slot">
          </b-carousel-slide>
          <b-carousel-slide>
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../static/img/slider/2.jpg" alt="image slot">
          </b-carousel-slide>
          <b-carousel-slide>
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../static/img/slider/3.jpg" alt="image slot">
          </b-carousel-slide>
          <b-carousel-slide>
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../static/img/slider/ai_2.png" alt="image slot">
          </b-carousel-slide>
        </b-carousel>
      </b-card> -->
      <b-row>
        <b-col sm="12" md="2" style="text-align: center;">
          <br>
          <img src="../../static/img/slider/image12.png" style="border-radius: 10px; width: 100px;">
          <br>
          <span style="color: white;">{{ $t('wdm16.11023') }}</span>
          <br>
          <br>
          <img src="../../static/img/slider/image13.png" style="border-radius: 10px; width: 100px;">
          <br>
          <span style="color: white;">{{ $t('wdm16.11024') }}</span>
        </b-col>
        <b-col sm="12" md="8">
          <iframe width="100%" height="500px;" src="https://www.youtube.com/embed/videoseries?list=PLYb7X_MFkOaDzFJ917URxtm6-r1kj8fl5&autoplay=1&loop=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          <!-- <iframe width="100%" height="500px;" src="http://www.youtube.com/embed/videoseries?list=PLYb7X_MFkOaDzFJ917URxtm6-r1kj8fl5&loop=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          <!-- <video controls width="100%">
            <source src="../../static/vid/WAI - 1 Wai Hakkında.mp4" type="video/mp4">
          </video> -->
        </b-col>
        <b-col sm="12" md="2" style="text-align: center;">
          <img src="../../static/img/slider/image14.png" style="border-radius: 10px; width: 100px;">
          <br>
          <span style="color: white;">
              {{ $t('wdm16.11025') }}
            </span>
          <br>
          <img src="../../static/img/slider/image15.png" style="border-radius: 10px; width: 100px;">
          <br>
          <span style="color: white;"> {{ $t('wdm16.11026') }} </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <blockquote class="card-blockquote" style="color: white;">
            <p>
              {{ $t('wdm16.11027') }}
            </p>
            <footer>
              <cite>{{ $t('wdm16.11028') }}</cite>
            </footer>
          </blockquote>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="4">
          <div class="wisdombox" style="height: 300px; margin: 20px;">
            <div class="wisdombox-inner">
              <div class="wisdombox-front" style="height: 100%; text-align: center;">
                <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11013') }}</span>
                <img src="../../static/img/slider/image5.png" style="border-radius: 10px; width: 100%;">
              </div>
              <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                <img src="../../static/img/slider/image5.png" style="border-radius: 10px; width: 100px;">
                <br> {{ $t('wdm16.11029') }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="4">
          <div class="wisdombox" style="height: 300px; margin: 20px;">
            <div class="wisdombox-inner">
              <div class="wisdombox-front" style="height: 100%; text-align: center;">
                <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11014') }}</span>
                <img src="../../static/img/slider/image6.png" style="border-radius: 10px; width: 100%;">
              </div>
              <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                <img src="../../static/img/slider/image6.png" style="border-radius: 10px; width: 100px;">
                <br> {{ $t('wdm16.11030') }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="4">
          <div class="wisdombox" style="height: 300px; margin: 20px;">
            <div class="wisdombox-inner">
              <div class="wisdombox-front" style="height: 100%; text-align: center;">
                <span style="color: #6ed0c7; font-size: 16px;">{{ $t('wdm16.11015') }}</span>
                <img src="../../static/img/slider/image7.png" style="border-radius: 10px; width: 100%;">
              </div>
              <div class="wisdombox-back" style="height: 100%; color: white; text-align: center; font-size: 14px;">
                <img src="../../static/img/slider/image7.png" style="border-radius: 10px; width: 100px;">
                <br> {{ $t('wdm16.11031') }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 100px;">
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-card class="bg-dark text-center">
                <blockquote class="card-blockquote">
                  <img src="../../static/img/slider/image8.png" style="border-radius: 10px; width: 100px;">
                  <p>{{ $t('wdm16.11032') }}</p>
                  <footer>
                    <cite title="Karar Destek Sistemleri"><strong>{{ $t('wdm16.6307') }}</strong></cite>
                  </footer>
                </blockquote>
              </b-card>
            </b-col>
            <b-col sm="12">
              <b-card class="bg-dark text-center">
                <blockquote class="card-blockquote">
                  <img src="../../static/img/slider/image10.png" style="border-radius: 10px; width: 100px;">
                  <p>{{ $t('wdm16.11033') }}</p>
                  <footer>
                    <cite title="Bulut Bilişim"><strong>{{ $t('wdm16.11034') }}</strong></cite>
                  </footer>
                </blockquote>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-card class="bg-dark text-center">
                <blockquote class="card-blockquote">
                  <img src="../../static/img/slider/image9.png" style="border-radius: 10px; width: 100px;">
                  <p>{{ $t('wdm16.11035') }}</p>
                  <footer>
                    <cite title="Yapay Öğrenme"><strong>{{ $t('wdm16.11036') }}</strong></cite>
                  </footer>
                </blockquote>
              </b-card>
            </b-col>
            <b-col sm="12">
              <b-card class="bg-dark text-center">
                <blockquote class="card-blockquote">
                  <img src="../../static/img/slider/image11.png" style="border-radius: 10px; width: 100px;">
                  <p>{{ $t('wdm16.11037') }}</p>
                  <footer>
                    <cite title="Büyük Veri"><strong>{{ $t('wdm16.11038') }}</strong></cite>
                  </footer>
                </blockquote>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    <web-bottom></web-bottom>
    </b-card>
    <!--
    <div class="row">
      <div class="col-md-6 col-sm-6 col-centered">
        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/h7EqIoPPqqw" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  -->
  </div>
</template>

<script>
import PoleStarService from '@/services/polestar';
import WaiService from '@/services/wai';
import moment from 'moment';
import {
  default as DrawAlgorithm
} from '@/components/widgets/DrawAlgorithm';
import { mapGetters } from 'vuex';
import WebBottom from '@/components/widgets/WebBottom';
export default {
  name: 'dashboard',
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  components: {
    WebBottom,
    DrawAlgorithm
  },
  data () {
    return {
      d_searchWaimList: [],
      d_searchWisdomEraAiRegion: '',
      slide: 0,
      sliding: null,
      d_nextWaiInterval: 0,
      d_nextDrawAlgorithm: 0,
      d_waiData: {
        'last_wai': '0',
        'wai': [],
        'count': 2,
        'published_dmp_count': 0
      },
      d_algorithmData: {
        'show': true,
        'data': [],
        'dmp_rule_slider': 'all_dmp',
        'stop': 0,
        'next': 0,
        'update_canvas': 0,
        'name': ''
      },
      d_drawSettings: {
        'canvas': {
          'height': 350,
          'width': 750
        },
        'header_variant': 'dark',
        'biggest_circle_radius': 30,
        'show_text': 1,
        'size': 200,
        'animation': 0,
        'lineWidth': 3,
        'colorAnimation': 1,
        'colorSpeed': 500,
        'rotateAnimation': 1,
        'shift': 1,
        'mainColor': this.f_calculateRGBA(),
        'speed': 100,
        'deep_animation_speed': 500,
        'max_deep': 0,
        'deep': 0,
        'deep_direction': 'to_children',
        'deep_animation': 0,
        'show_buttons': 0
      }
    };
  },
  mounted: function () {},
  created: function () {
    this.d_drawSettings.canvas.width = window.innerWidth;
    // this.f_getNextDrawAlgorithm();
    // this.f_getNextDrawAlgorithmStartInterval();
    this.f_getNextWai();
    this.f_getNextWaiStartInterval();
  },
  destroyed () {
    clearInterval(this.d_nextWaiInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    },
    f_goToWaimWdm3Project: function (wdm3_id) {
      let route_data = {
        'name': 'polestarwaimtalk',
        'path': '/whsmodules/polestar/waim/talk',
        'query': { 'waim_id': wdm3_id }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm24: function (wdm24_id) {
      let route_data = {
        'name': 'polestarwaimtalkalgorithm',
        'path': '/whsmodules/polestar/waim/talk-algorithm',
        'query': { 'waim_id': wdm24_id }
      };
      this.$router.push(route_data);
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    f_getNextWai: function () {
      let query = 'last_wai=' + this.d_waiData.last_wai.toString() + '&count=' + this.d_waiData.count.toString();
      WaiService.get_next_wai(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_waiData.last_wai = resp.data.result.last_wai;
            this.d_waiData.wai = resp.data.result.wai;
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_getNextWaiStartInterval: function () {
      this.d_nextWaiInterval = setInterval(function () {
        this.f_getNextWai();
      }.bind(this), 10000);
    },
    f_searchWaim: function () {
      let data = { 'search': this.d_searchWisdomEraAiRegion };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yapay zeka modellerinde arama işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.search_waim(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_searchWaimList = resp.data.result.list;
            if (this.d_searchWaimList.length > 0) {
              let route_data = {
                'name': 'polestarwaim',
                'path': '/whsmodules/polestar/waim',
                // 'params': this.d_searchWaimList => does not work
                'query': { 'waim_text': this.d_searchWisdomEraAiRegion }
              };
              this.$router.push(route_data);
              // this.d_showWaimListModal = true;
            } else {
              alert('Belirttiğiniz kriterlere uygun yapay zeka modeli bulunamadı.');
            }
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    ClickEnter: function () {
      if (event.which === 13) {
        this.f_searchWaim();
      }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  },
  watch: {}
}

</script>

<style type="text/css">
#card1 {
  -webkit-transition: -webkit-transform .8s ease-in-out;
  transition: transform .8s ease-in-out;
}

#card1:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

#WaiListScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#WaiListScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#WaiListScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

